import vampImage from './images/eb_sb_001.jpg';
import girl from './images/eb_sb_002.jpg';
import self from './images/eb_sb_003.jpg';
import cherry from './images/eb_sb_004.jpg';
import treeFairy from './images/eb_sb_005.jpg';
import style from './images/eb_sb_006.jpg';
import uhOh from './images/eb_sb_007.jpg';
import abbey from './images/eb_sb_008.jpg';
import avatar from './images/eb-avatar.jpg';
import about from './images/about.jpg';

const config = {
  siteProps: {
    primaryBg: 'blue.600',
    primaryColor: 'white',
    maxWidth: '62em',
  },
  splashScreen: {
    id: 'splashScreen',
    siteLink: 'Home',
    title: 'Emily Blu',
    subTitle: 'sketches',
    avatar: {
      id: 'myAvatar',
      title: 'My Avatar',
      src: avatar,
      altText: 'Emily Blu',
    },
  },
  books: [
    {
      id: 'book-1',
      siteLink: 'Sketches',
      name: 'Sketch Book',
      images: [
        {
          id: 'fox-anime',
          title: 'Fang',
          description:
            'This piece came from my first anime lesson.  My favorite animal is the fox - I love them.  So that means that I draw them.  A LOT.  So, I just thought that I would start with what I already knew.  I drew this character with some recognizable fox features.',
          src: vampImage,
          altText: 'Cool Anime Sketch',
          palette: {
            primary: '#FE7702',
            secondary: '#fff',
            colors: ['#FE7702', '#FBA9CF', '#FCD08F', '#F1E2C2', '#000000'],
          },
        },
        {
          id: 'uhOh',
          title: 'Uh Oh!',
          description:
            "This was a sketch where I was just practicing expression, especially with the mouth.  That's really if for this one.",
          src: uhOh,
          altText: 'Uh oh!',
          palette: {
            primary: '#064F7F',
            secondary: '#fff',
            colors: ['#064F7F', '#2F9770', '#FE94A1', '#C57621', '#A563A1'],
          },
        },
        {
          id: 'abby',
          title: 'Abby',
          description:
            'This is a sketch I did for my friend.  I did it in a more "cartoon-ish" style.  Interesting fact: You know how I like foxes?  Well, that\'s how Abby feels about raccoons.',
          src: abbey,
          altText: 'Abbey',
          palette: {
            primary: '#0364BA',
            secondary: '#fff',
            colors: ['#0364BA', '#FA7205', '#FCFEFE', '#FED28A', '#BCC208'],
          },
        },
        {
          id: 'tree',
          title: 'Tree Fairy',
          description:
            'Pellentesque in ipsum id orci porta dapibus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          src: treeFairy,
          altText: 'Tree Fairy',
          palette: {
            primary: '#619639',
            secondary: '#fff',
            colors: ['#619639', '#E1EC72', '#DDA645', '#FBE7C4', '#FEBCD9'],
          },
        },
        {
          id: 'self',
          title: 'Self Portrait',
          description:
            'Pellentesque in ipsum id orci porta dapibus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          src: self,
          altText: 'Portrait of the artist as a young artist.',
          palette: {
            primary: '#A2282D',
            secondary: '#fff',
            colors: ['#A2282D', '#FAAD68', '#8C6242', '#D38B37', '#000'],
          },
        },
        {
          id: 'cherry',
          title: 'Cherry on Top',
          description:
            'Pellentesque in ipsum id orci porta dapibus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          src: cherry,
          altText: 'Cherry on Top',
          palette: {
            primary: '#B384C9',
            secondary: '#fff',
            colors: ['#B384C9', '#FEA1CD', '#DB965E', '#FE413A', '#000'],
          },
        },
        {
          id: 'style',
          title: 'Your Style',
          description:
            'For this sketch, I just came up with an idea, drew it, then put the title "Draw This In Your Style."  I\'m not sure exactly what my style is - it seems to be constantly changing.  Maybe that\'s my style...',
          src: style,
          altText: 'Find Your Own Style',
          palette: {
            primary: '#711F1B',
            secondary: '#fff',
            colors: ['#711F1B', '#FF8AB2', '#024174', '#D1955B', '#71AABE'],
          },
        },
        {
          id: 'girl',
          title: 'Anime Portrait',
          description:
            'Pellentesque in ipsum id orci porta dapibus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          src: girl,
          altText: 'Colorful',
          palette: {
            primary: '#025CA1',
            secondary: '#fff',
            colors: ['#025CA1', '#FEC5E7', '#DA7E19', '#C3E666', '#FE778A'],
          },
        },
      ],
    },
  ],
  about: {
    id: 'about',
    siteLink: 'About',
    title: 'Me',
    description:
      "The first thing you should know about me is that I have awesome parents.  Seriously, and I'm not just saying this because my dad wrote this and put it in here until I actually write my own 'About' description.  In the meantime, this will be here just to remind me how totally amazing my parents are and how I should listen to them more, clean up my room, and generally just do whatever they want me to without questioning EVERYTHING.  And just in case you were wondering: yes, my parents are right about everything.  Even those times where I was so sure that I was right and they were wrong and I rolled my eyes at them and then stomped up the stairs and slammed my door shut.  Yep - it turns out, even then, I was WRONG and they were RIGHT.",
    src: about,
    altText: 'Me',
  },
};

export { config };
