import { useEffect, useState, useMemo } from 'react';
import { useConfig } from 'components/ConfigProvider';

const IMAGE_LOADING_STATUS = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const useLoadImage = imgData => {
  const [status, setStatus] = useState(IMAGE_LOADING_STATUS.LOADING);

  useEffect(() => {
    let mounted = true;

    const loadImage = () => {
      const { src } = imgData;
      const img = new Image();

      img.onload = () => {
        if (mounted) {
          setStatus(IMAGE_LOADING_STATUS.SUCCESS);
        }
      };

      img.onerror = () => {
        if (mounted) {
          setStatus(IMAGE_LOADING_STATUS.ERROR);
        }
      };

      img.src = src;
    };

    loadImage();

    return () => {
      mounted = false;
    };
  }, [status, imgData]);

  return status;
};

const usePreloadImages = () => {
  const config = useConfig();
  const [loaded, setLoaded] = useState([]);

  const imgArr = useMemo(() => {
    return [
      config.splashScreen.avatar,
      config.about,
      ...config.books
        .map(book => book.images)
        .reduce((accum, arr) => {
          return [...accum, ...arr];
        }, []),
    ];
  }, [config]);

  useEffect(() => {
    let mounted = true;
    let idx = 0;

    const timerId = setInterval(() => {
      if (idx === imgArr.length) {
        clearInterval(timerId);
        return;
      }

      const { name, title, id, src } = imgArr[idx++];
      const img = new Image();

      img.onload = () => {
        if (mounted) {
          setLoaded(prevArr => [
            ...prevArr,
            { id, image: name || title, status: 'LOADED' },
          ]);
        }
      };

      img.onerror = () => {
        if (mounted) {
          setLoaded(prevArr => [
            ...prevArr,
            { id, image: name || title, status: 'ERROR' },
          ]);
        }
      };

      img.src = src;
    }, 100);

    return () => {
      clearInterval(timerId);
      mounted = false;
    };
  }, [imgArr]);

  const imagesLoaded = loaded.filter(
    result => result.status !== IMAGE_LOADING_STATUS.LOADING
  ).length;

  const progress = !imagesLoaded
    ? 0
    : Math.min((imagesLoaded / imgArr.length) * 100, 100);

  return {
    isLoading: loaded.length !== imgArr.length,
    results: loaded,
    progress,
    data: imgArr,
  };
};

export { usePreloadImages, useLoadImage, IMAGE_LOADING_STATUS };
