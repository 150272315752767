import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@emotion/react';
import ViewProvider from 'components/ViewProvider';
import ConfigProvider from 'components/ConfigProvider';
import { config } from 'config/sb-config';

import theme from 'theme';
import 'index.css';

ReactDOM.render(
  <React.StrictMode>
    <ViewProvider>
      <ConfigProvider config={config}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ConfigProvider>
    </ViewProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
