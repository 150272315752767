import '@fontsource/roboto';
import '@fontsource/roboto/900.css';
import { Box, FadeIn, FlexBox } from 'elements';
import React, { useState } from 'react';
import SketchItem from 'components/SketchItem';
import { usePreloadImages } from 'hooks/ui-hooks';
import SplashScreen from 'components/SplashScreen';
import GlobalStyle from 'components/GlobalStyle';
import NavBar from 'components/NavBar';
import { useConfig } from 'components/ConfigProvider';
import About from 'components/About';

function App() {
  const config = useConfig();
  const { isLoading, progress, results } = usePreloadImages();
  const [splashScreenVisible, setSplashScreenVisible] = useState(true);

  const isAvatarLoaded = !!results.find(
    result => result.id === config.splashScreen.avatar.id
  );

  return (
    <>
      <GlobalStyle />
      <NavBar
        progress={progress}
        bg={splashScreenVisible ? 'transparent' : 'blue.600'}
      />
      <SplashScreen
        onViewChange={setSplashScreenVisible}
        isAvatarLoaded={isAvatarLoaded}
        isReady={!isLoading}
      />
      {!isLoading && (
        <FadeIn>
          <FlexBox
            id={config.books[0].id}
            pt={8}
            pb={4}
            px={[2, 4, 8]}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ flexFlow: 'wrap' }}
          >
            {config.books[0].images.map(iConfig => {
              return (
                <Box m={4} key={iConfig.id}>
                  <SketchItem sketchConfig={iConfig} />
                </Box>
              );
            })}
          </FlexBox>
          <About />
        </FadeIn>
      )}
    </>
  );
}

export default App;
