import React from 'react';
import { debounce } from 'utils/js-utils';

const viewContext = React.createContext({});

const ViewProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    const debouncedResizeListener = debounce(handleWindowResize, 100);

    window.addEventListener('resize', debouncedResizeListener);
    return () => window.removeEventListener('resize', debouncedResizeListener);
  }, [setWidth, setHeight]);

  return (
    <viewContext.Provider value={{ width, height }}>
      {children}
    </viewContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewContext);
  return { width, height };
};

export { useViewport };
export default ViewProvider;
