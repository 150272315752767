import { CenterBox, Image, Text, VerticalCenterBox } from 'elements';
import { useConfig } from 'components/ConfigProvider';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown } from 'react-icons/fa';
import { keyframes } from '@emotion/react';
import placeHolderImg from 'config/images/avatar-ph.jpg';
import { useInView } from 'react-intersection-observer';

const gentleBounce = keyframes`
  0% { transform: translateY(0px) }
  50% { transform: translateY(5px) }
  100% { transform: translateY(0px) }
`;

const SplashScreen = ({ onViewChange, isAvatarLoaded, isReady, ...rest }) => {
  const {
    splashScreen: { id, title, subTitle, avatar },
    siteProps: { primaryBg, primaryColor },
  } = useConfig();

  const { ref, inView } = useInView({ threshold: 0.3 });
  const { ref: avatarRef, inView: isAvatarVisible } = useInView();

  useEffect(() => {
    onViewChange(inView);
  }, [inView, onViewChange]);

  const imgProps = isAvatarLoaded ? avatar : { ...avatar, src: placeHolderImg };

  return (
    <VerticalCenterBox
      id={id}
      height={'100vh'}
      position={'relative'}
      bg={primaryBg}
      ref={ref}
      {...rest}
    >
      <Text
        as={'h1'}
        color={primaryColor}
        mb={4}
        fontSize={'5xl'}
        letterSpacing={'1px'}
        textAlign={'center'}
      >
        {title}
        <Text
          as={'span'}
          display={'block'}
          fontWeight={'normal'}
          letterSpacing={'wider'}
          fontSize={'3xl'}
          sx={{
            textTransform: 'lowercase',
          }}
        >
          {subTitle}
        </Text>
      </Text>
      <Image
        ref={avatarRef}
        {...imgProps}
        width={'256px'}
        boxShadow="2xl"
        borderRadius={'full'}
      />
      <CenterBox
        mt={4}
        p={4}
        borderRadius={'full'}
        fontSize={['xl', 'xl', '3xl']}
        color={primaryColor}
        sx={{
          transition: 'opacity .5s',
          opacity: isReady && isAvatarVisible ? 1 : 0,
          animation: `${gentleBounce} 1s linear infinite 1s`,
        }}
      >
        <FaChevronDown />
      </CenterBox>
    </VerticalCenterBox>
  );
};

SplashScreen.props = {
  isAvatarLoaded: PropTypes.bool,
  isReady: PropTypes.bool,
};

SplashScreen.defaultProps = {
  isAvatarLoaded: false,
  isReady: false,
};

export default SplashScreen;
