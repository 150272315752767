import { FaCircleNotch } from 'react-icons/fa';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(FaCircleNotch)`
  animation: ${spinAnimation} infinite 2s linear;
`;

export default Spinner;
