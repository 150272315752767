import React from 'react';
import { css, Global } from '@emotion/react';

const globalStyleCss = css`
  *,
  *::before {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: 'roboto', sans-serif;
    position: relative;
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #f1f1f1;
    position: relative;
  }

  #root {
    height: 100%;
  }
`;

const GlobalStyle = () => <Global styles={globalStyleCss} />;

export default GlobalStyle;
