import PropTypes from 'prop-types';
import { Image } from 'elements';

const Avatar = ({ name, imageSrc, ...props }) => {
  return <Image alt={name} src={imageSrc} variant={'avatar'} {...props} />;
};

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
};

export default Avatar;
