import styled from '@emotion/styled';
import css, { get } from '@styled-system/css';
import {
  compose,
  space,
  layout,
  typography,
  position,
  color,
  border,
  boxShadow,
  flexbox,
} from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';

const sx = props => css(props.sx)(props.theme);
const base = props => css(props.__css)(props.theme);

const variant = ({ theme, variant, themeKey = 'variants' }) =>
  css(get(theme, themeKey + '.' + variant, get(theme, variant)))(theme);

const Box = styled('div', { shouldForwardProp })(
  {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0,
  },
  base,
  variant,
  sx,
  props => props.css,
  compose(
    space,
    layout,
    typography,
    color,
    border,
    flexbox,
    position,
    boxShadow
  )
);

const FlexBox = styled(Box)({
  display: 'flex',
});

const AbsBox = styled(Box)({
  position: 'absolute',
});

const CenterBox = styled(FlexBox)({
  justifyContent: 'center',
  alignItems: 'center',
});

const VerticalCenterBox = styled(CenterBox)({
  flexDirection: 'column',
});

export { Box, FlexBox, AbsBox, CenterBox, VerticalCenterBox };
