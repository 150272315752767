import React from 'react';
import SketchBox from 'components/SketchBox';
import { Box, FadeIn, FlexBox, Image, Text } from 'elements';
import Avatar from 'components/Avatar';

const Palette = ({ colors }) => {
  return (
    <FlexBox justifyContent={'center'} alignItems={'center'}>
      {colors.map(color => {
        return (
          <Box
            width={[2, 3, 6]}
            bg={color}
            key={color}
            height={[2, 3, 6]}
            title={color}
            mx={[1, 1, 2]}
            borderRadius={'full'}
            boxShadow={'dark-lg'}
          />
        );
      })}
    </FlexBox>
  );
};

const SketchText = props => (
  <Text
    fontSize={['xs', 'xs', 'sm']}
    color="gray.700"
    lineHeight={['normal', 'base', 'tall']}
    letterSpacing={'wide'}
    {...props}
  />
);

const SketchItem = ({ sketchConfig }) => {
  const {
    src,
    altText,
    title,
    description,
    palette: { colors, primary, secondary },
  } = sketchConfig;

  return (
    <SketchBox
      height={['15rem', '18rem', '30rem']}
      width={['15rem', '18rem', '30rem']}
      accentColor={primary}
      renderFront={() => (
        <FlexBox
          height={'100%'}
          width={'full'}
          justifyContent={'center'}
          alignItems={'center'}
          bg={primary}
          color={'white'}
          borderRadius={'2xl'}
          boxShadow={'2xl'}
        >
          <FadeIn height={'100%'}>
            <Image src={src} alt={altText} borderRadius={'2xl'} />
          </FadeIn>
        </FlexBox>
      )}
      renderBack={() => (
        <FlexBox
          height={'100%'}
          width={'full'}
          borderRadius={'2xl'}
          justifyContent={'flex-start'}
          borderColor={primary}
          bg={secondary}
          boxShadow={'2xl'}
        >
          <FlexBox
            alignItems={'center'}
            height={'100%'}
            bg={primary}
            borderTopLeftRadius={'2xl'}
            borderBottomLeftRadius={'2xl'}
            border={'1px'}
            borderColor={primary}
            minWidth={'30%'}
            flexDirection={'column'}
            pt={3}
          >
            <Avatar
              imageSrc={src}
              name={title}
              height={['48px', '48px', '96px']}
              width={['48px', '48px', '96px']}
              mb={[2, 4, 6]}
              boxShadow={'xl'}
            />
          </FlexBox>
          <FlexBox
            flexDirection={'column'}
            pr={8}
            pl={4}
            pt={4}
            pb={8}
            justifyContent={'space-between'}
          >
            <Box>
              <Text
                as={'h2'}
                fontSize={['xl', 'xl', '3xl']}
                mb={2}
                color={'gray.700'}
              >
                {title}
              </Text>
              <SketchText>{description}</SketchText>
            </Box>
            <Palette colors={colors} />
          </FlexBox>
        </FlexBox>
      )}
    />
  );
};

export default SketchItem;
