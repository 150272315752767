import React from 'react';

const ConfigContext = React.createContext({});

const ConfigProvider = ({ config, ...rest }) => {
  return <ConfigContext.Provider value={config} {...rest} />;
};

const useConfig = () => {
  const config = React.useContext(ConfigContext);
  return config;
};

export { useConfig };
export default ConfigProvider;
