import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, FlexBox } from 'elements';

const StyledSketchBox = styled(Box)`
  perspective: 150rem;
  position: relative;
`;

const StyledSide = styled(Box)`
  width: 100%;
  height: 100%;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`;

const StyledFrontSide = styled(StyledSide)`
  transform: ${props => (props.isVisible ? null : 'rotateY(180deg)')};
`;

const StyledBackSide = styled(StyledSide)`
  transform: ${props =>
    props.isVisible ? 'rotateY(0deg)' : 'rotateY(180deg)'};
`;

const StyledLine = styled(Box)`
  height: 1px;
  width: 20px;
  transition: all 0.4s linear;
  transition-delay: 0.2s;
`;

const SketchBoxButton = ({ onClick, showClose, accentColor, isDisabled }) => {
  return (
    <FlexBox
      as={'button'}
      aria-label={'Sketch Info'}
      borderRadius={'full'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
      onClick={onClick}
      bg={'transparent'}
      position={'absolute'}
      top={'.5rem'}
      right={'.5rem'}
      color={'white'}
      border={'1px'}
      borderColor={accentColor}
      width={'30px'}
      height={'30px'}
      zIndex={999}
      disabled={isDisabled}
      sx={{
        cursor: 'pointer',
        outline: 'none',
      }}
    >
      <StyledLine
        bg={accentColor}
        sx={
          showClose
            ? { transform: 'rotate(-45deg)' }
            : { transform: 'rotate(90deg)' }
        }
        position={'absolute'}
      />
      <StyledLine
        bg={accentColor}
        sx={showClose ? { transform: 'rotate(45deg)' } : null}
        position={'absolute'}
      />
    </FlexBox>
  );
};

const SketchBox = ({
  renderFront,
  renderBack,
  accentColor,
  isDisabled,
  ...rest
}) => {
  const [showFront, setShowFront] = useState(true);

  return (
    <StyledSketchBox {...rest}>
      <SketchBoxButton
        onClick={() => setShowFront(val => !val)}
        showClose={!showFront}
        accentColor={accentColor}
        isDisabled={isDisabled}
      />
      <StyledFrontSide isVisible={showFront}>
        {renderFront(showFront)}
      </StyledFrontSide>
      <StyledBackSide isVisible={!showFront}>
        {renderBack(!showFront)}
      </StyledBackSide>
    </StyledSketchBox>
  );
};

SketchBox.propTypes = {
  renderFront: PropTypes.func.isRequired,
  renderBack: PropTypes.func.isRequired,
  accentColor: PropTypes.string,
};

SketchBox.defaultProps = {
  accentColor: 'gray.300',
};

export default SketchBox;
