import React from 'react';
import { Box, Image, Text } from 'elements';
import { useConfig } from 'components/ConfigProvider';

const About = () => {
  const {
    about: { id, src, title, altText, description, siteLink },
  } = useConfig();

  return (
    <Box
      width={'full'}
      bg={'blue.600'}
      pt={'4rem'}
      minHeight={'100vh'}
      pb={'2rem'}
      px={4}
    >
      <Text
        as={'h2'}
        fontSize={['xl', 'xl', '3xl']}
        mb={2}
        color={'white'}
        width={'full'}
        textAlign={'center'}
      >
        {siteLink}
      </Text>
      <Box maxWidth={'62em'} my={0} mx={'auto'} px={14}>
        <Image
          src={src}
          title={title}
          alText={altText}
          width={['128px', '128px', '256px']}
          boxShadow="2xl"
          borderRadius={'full'}
          mr={2}
          mb={2}
          sx={{
            transition: 'width 0.3s ease-in',
            float: 'left',
            display: 'block',
            shapeOutside: 'circle(50%)',
          }}
        />

        <Text
          mt={2}
          id={id}
          color="white"
          maxWidth={'62em'}
          lineHeight={['normal', 'base', 'tall']}
        >
          {`${description}`}
        </Text>
      </Box>
    </Box>
  );
};

export default About;
