import { Box } from './boxes';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const FadeIn = styled(Box)`
  opacity: 0;
  animation: ${fadeIn} 1.5s linear forwards ${props => props.delay || null};
`;

export { FadeIn };
