import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FlexBox } from 'elements';
import Spinner from 'components/Spinner';
import styled from '@emotion/styled';
import { useConfig } from 'components/ConfigProvider';
import { Link } from 'react-scroll';

const BOX_SIZE = '40px';

const NavBarContainer = props => (
  <FlexBox
    as={'nav'}
    alignItems={'center'}
    justifyContent={'center'}
    position={'fixed'}
    top={0}
    left={0}
    width={'full'}
    {...props}
  />
);

const NavBarInner = props => (
  <FlexBox
    alignItems={'center'}
    justifyContent={'flex-start'}
    maxWidth={'62em'}
    width={'full'}
    py={2}
    pl={2}
    {...props}
  />
);

const Circle = props => (
  <FlexBox
    position={'relative'}
    height={BOX_SIZE}
    width={BOX_SIZE}
    justifyContent={'center'}
    alignItems={'center'}
    border={'1px'}
    borderColor={'white'}
    borderRadius={'full'}
    flexDirection={'column'}
    {...props}
  />
);

const StyledCircle = styled(Circle)`
  overflow: hidden;
  &::before {
    content: '';
    transition: height 0.2s linear;
    position: absolute;
    width: ${BOX_SIZE};
    bottom: 0;
    left: 0;
    height: ${props => props.progress}%;
    background-color: ${props => props.theme.colors.yellow['400']};
  }
`;

const StyledLine = styled(Box)`
  height: 1px;
  width: 20px;
  background-color: white;
  transition: all 0.4s linear;
  transition-delay: 0.2s;
`;

const CircleProgress = props => (
  <StyledCircle {...props}>
    <Spinner color={'white'} fontSize={22} />
  </StyledCircle>
);

const SiteLink = styled(Link)`
  &:hover {
    cursor: pointer;
  }

  &.active {
    font-weight: bold;
  }
`;

const NavControl = () => {
  const config = useConfig();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(prev => !prev);
  return (
    <>
      <Circle
        as={'button'}
        bg={'blue.600'}
        sx={{ outline: 'none', '&:hover': { cursor: 'pointer' } }}
        onClick={toggle}
        zIndex={999}
      >
        <StyledLine
          my={open ? 0 : 1}
          sx={open ? { transform: 'rotate(45deg)' } : null}
        />
        <StyledLine
          position={'relative'}
          top={open ? '-1px' : 0}
          my={open ? 0 : 1}
          sx={open ? { transform: 'rotate(-45deg)' } : null}
        />
      </Circle>
      <FlexBox
        px={4}
        color="white"
        sx={{
          opacity: open ? 1 : 0,
          transition: `opacity 0.5s linear`,
        }}
      >
        <SiteLink
          activeClass="active"
          to={config.splashScreen.id}
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          {config.splashScreen.siteLink}
        </SiteLink>
        <Box mx={2} />
        <SiteLink
          activeClass="active"
          to={config.books[0].id}
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          {config.books[0].siteLink}
        </SiteLink>
        <Box mx={2} />
        <SiteLink
          activeClass="active"
          to={config.about.id}
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
        >
          {config.about.siteLink}
        </SiteLink>
      </FlexBox>
    </>
  );
};

const NavBar = ({ progress, bg }) => {
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    let mounted = true;
    if (progress >= 100) {
      setTimeout(() => {
        if (mounted) {
          setIsInitializing(false);
        }
      }, 250);
    }
    return () => (mounted = false);
  });

  return (
    <NavBarContainer zIndex={999} bg={bg}>
      <NavBarInner>
        {isInitializing && <CircleProgress progress={progress} />}
        {!isInitializing && <NavControl />}
      </NavBarInner>
    </NavBarContainer>
  );
};

NavBar.propTypes = {
  progress: PropTypes.number.isRequired,
  bg: PropTypes.string.isRequired,
};

export default NavBar;
